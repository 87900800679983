<template>
  <v-list class="pa-0 ma-0" dense>
    <v-list-item
      class="px-2 expande-horizontal"
      @click="copyLinkForTransferenceArea"
    >
      <div class="expande-horizontal column">
        <span class="fonte-bold">
          Copiar Link da Loja
        </span>
        <span class="fonte fonteMini grey--text">
           {{ getLoggedUser.tenant[0].link_menurocket || "Sem link cadastrado" }}
        </span>
      </div>
      <v-spacer></v-spacer>
      <v-icon size="18" :color="$theme.primary" class="mr-1">mdi-content-copy</v-icon>
    </v-list-item>
    <v-divider></v-divider>
  </v-list>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClipboardJS from "clipboard";

export default {
  data() {
    return {
      myLink: ""
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  methods: {
    ...mapActions(["createGlobalMessage", "createGlobalMessages"]),
    linkMount() {
      this.myLink = `https://market.scnrevolution.com/#/${this.getLoggedUser.tenant[0].link_menurocket}`;
      // this.myLink = `http://192.168.1.68:8081/#/${this.getLoggedUser.tenant[0].link_menurocket}`;
      new ClipboardJS(".btn1");
    },
    copyLinkForTransferenceArea() {
      const el = document.createElement("textarea");
      el.value = this.myLink;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.createGlobalMessage({
        message: "Link copiado para a área de transferência",
        type: "success",
        timeout: 3000
      });
    },
    openMyLink() {
      window.open(this.myLink, "_blank");
    }
  },
  mounted() {
    this.linkMount();
  }
};
</script>
