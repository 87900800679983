<template>
  <v-list class="pa-0 ma-0 fonte">
    <v-list-item @click="() => {}" class="pa-0 px-3 ma-0">
      <v-list-item-content @click="openMyLink">
        <v-list-item-title class="fonte-bold dark--text">
          <div class="expande-horizontal" style="align-items: center;">
            {{ opened ? "Aberto" : "Fechado" }}
            <v-progress-linear
              class="ml-3 mb-1"
              style="max-width: 140px; border-radius: 6px; height: 15px;"
              :color="opened ? $theme.primary : 'orange'"
              width="50"
              :indeterminate="opened"
            >
              <span class="fonte-bold white--text fonteMini" v-if="opened"
                >Aguardando pedidos</span
              >
              <span class="fonte-bold black--text fonteMini" v-else
                >Pedidos externos desativados</span
              >
            </v-progress-linear>
          </div>
        </v-list-item-title>
        <span class="fonte fonteMini grey--text" style="cursor: pointer;">
          <span v-if="opened">clique para acessar</span>
        </span>
      </v-list-item-content>
      <v-list-item-action>
        <div v-if="loading">
          <v-progress-circular
            x-small
            indeterminate
            :color="$theme.primary"
          ></v-progress-circular>
        </div>
        <v-switch
          v-else
          dense
          :color="$theme.primary"
          x-small
          v-model="opened"
          @click="changeDeliveryStatus"
        ></v-switch>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClipboardJS from "clipboard";

export default {
  data() {
    return {
      loading: false,
      opened: false,
      myLink: ""
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    changeDeliveryStatus() {
      this.loading = true;
      setTimeout(() => {
        if (this.opened) {
          this.openDelivery();
        } else {
          this.closeDelivery();
        }
      }, 500);
    },
    closeDelivery() {
      this.loading = true;
      this.$run("marketplaces/close-delivery").then(() => {
        this.loading = false;
        this.opened = false;
        this.$eb.$emit("changeDeliveryStatus", false);
      });
    },
    openDelivery() {
      this.loading = true;
      this.$run("marketplaces/open-delivery").then(() => {
        this.loading = false;
        this.opened = true;
        this.$eb.$emit("changeDeliveryStatus", true);
      });
    },
    getDeliveryStatus() {
      this.loading = true;
      this.$run("marketplaces/get-delivery-status").then(res => {
        this.loading = false;
        this.opened = res.data.delivery_is_active;
        this.$eb.$emit("changeDeliveryStatus", res.data.delivery_is_active);
      });
    },
    linkMount() {
      this.myLink = `https://market.scnrevolution.com/#/${this.getLoggedUser.tenant[0].link_menurocket}`;
      // this.myLink = `http://192.168.1.68:8081/#/${this.getLoggedUser.tenant[0].link_menurocket}`;
      new ClipboardJS(".btn1");
    },
    openMyLink() {
      window.open(this.myLink, "_blank");
    }
  },
  created() {
    this.getDeliveryStatus();
    this.linkMount();
  }
};
</script>
